:root {
  --contactlessPourBlue: #80daeb;
  --scrollbarBG: tan;
  --thumbBG: brown;
  --sidebarColor: #fffefd;
  --navbarColor: #e8f3e6;
  --mbCtrlBG: white;
  --placeholderText: #767676;
  --unratedColor: #16b1d0;
  --primaryText: black;
  --lightWhite: white;
  --alphaWhite: #ffffffbb;
  --secondaryText: blue;
  --navbarMenuColor: white;
  --linkColor: brown;
  --generalLinkColor: #bd0ebd;
  --checkedColor: #68aefa;
  --aboutContent: white;
  --ballotSection: tan;
  --modalThankYou: lightgreen;
  --reviewBtnColor: #6dff6d;
  --buttonColor: #eee;
  --nextBtn: #eee7b9;
  --disabledBtnColor: rgba(0, 0, 0, .5);
  --submit: #b9e8b6;
  --failureColor: pink;
  --registerBtn: tan;
  --settingOption: oldlace;
  --statusBarMargin: 38px;
  --horizStatusBarMargin: 18px;
  --closed: red;
  --venueCardText: darkgreen;
}

.display-false {
  display: none !important;
}

body {
  background-color: var(--sidebarColor);
  margin: 0;
  font-family: -apple-system, roboto, BlinkMacSystemFont, system-ui, sans-serif;
  line-height: 1.3;
  padding:
    env(safe-area-inset-top, 20px)
    env(safe-area-inset-right, 20px)
    env(safe-area-inset-bottom, 20px)
    env(safe-area-inset-left, 20px);
}

.sidebarStyle::-webkit-scrollbar {
  width: 11px;
}
.sidebarStyle {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.sidebarStyle::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.sidebarStyle::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

#topLevelContainer {
  font-size: 0pt;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

.mainContent {
  position: absolute;
  left: max(265px, 20%);
  right: 0;
  height: 100vh;
  overflow-y: hidden;
  z-index: 1;
}

.outerSidebarStyle {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin: 0;
  z-index: 2;
  width: 20%;
  min-width: 265px;
  top: calc(0 - var(--statusBarMargin));
  left: 0;
  height: calc(100% + var(--statusBarMargin));
  background: var(--sidebarColor);
}

.navigation {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  /* padding: calc(var(--horizStatusBarMargin)) 20px; */
  padding: 5px 20px 2px;
  font-size: 1.1rem;
  justify-content: space-between;
  align-items: baseline;
  z-index: 5;
  display: flex;
  width: auto;
  height: calc(44px + var(--horizStatusBarMargin));
  min-height: 40px;
  background-color: var(--navbarColor);
  border-bottom: 1px solid black;
}

.svg-inline--fa {
  pointer-events: none;
  color: var(--primaryText);
}

.filterPanelOption .svg-inline--fa, .badgeRow .svg-inline--fa {
  color: #2caf1a;
}

.navbarMenu {
  display: none;
  position: fixed;
  top: -15rem;
  width: max(245px, calc(20% - 20px));
  height: auto;
  background: var(--navbarMenuColor);
  transition: 0.5s;
  padding: 0 10px 10px;
  border-bottom: 1px solid var(--primaryText);
}

.navbarMenu.active {
  top: calc(53px + var(--horizStatusBarMargin));
  z-index: 2;
  display: unset;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
}

.navbarLinks {
  position: relative;
  display: flex;
  justify-content: space-between;
}

#profileThumbnail {
  font-size: 24px;
  text-align: center;
  margin: 0;
  color: var(--primaryText);
}

.navbarLinks ul {
  list-style-type: none;
  font-size: 24px;
  text-align: right;
  margin: 0;
  padding: 0;
}

li a {
  color: var(--linkColor);
  text-decoration: underline;
}

a {
  color: var(--generalLinkColor);
}

#settingsArea {
  width: 100%;
  margin-top: 10px;
}

#settingsBtn {
  font-size: 24px;
  background-color: var(--buttonColor);
  color: black;
  border-radius: 50px;
  width: 100%;
  border: 1px solid var(--primaryText);
}

.settingsMenu {
  margin: 6px 0 0;
}

.settingLabel {
  margin: 4px 0;
  width: 100%;
  position: relative;
  background-color: transparent;
  color: var(--primaryText);
  text-align: left;
  padding: 3px 2px 0;
  font-size: 16px;
  border: none;
  border-top: 1px solid var(--primaryText);
}

.settingLabel:focus {
  box-shadow: 0 0 0 1px #159ce4;
}

.settingQuestionCard {
  padding-bottom: 20px;
}

.settingQuestionCard h3 {
  margin: 6px 0 2px 0;
}

.openCloseLabel {
  position: absolute;
  right: 5px;
  justify-self: center;
}

.sliderBox {
  width: 100%;
  margin-right: 0;
}

.settingSlider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 16px;
  border-radius: 8px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.settingSlider:hover {
  opacity: 1;
}

.settingSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #2caf1a;
  cursor: pointer;
}

.settingSlider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #2caf1a;
  cursor: pointer;
}

.justifiedLabelsBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.justifiedLabelsBox p {
  color: var(--primaryText);
  margin: 0;
}
.navbar-nav {
  text-align: right;
}

a {
  text-decoration: unset;
}

.logo {
  position: relative;
  display: flex;
  color: pink;
	text-shadow: -1px 1px 2px #000,
				  1px 1px 2px #000,
				  1px -1px 0 #000,
				  -1px -1px 0 #000;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

.navigation .logo {
  font-size: 23px;
  left: -2px;
}

.navbarButton {
  font-size: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primaryText);
  font-family: -apple-system, roboto, BlinkMacSystemFont, system-ui, sans-serif;
}

#logoMug {
  height: 13px;
  width: 17px;
  padding: 6.7px 2.5px 5.3px 5.5px;
  margin-left: 6px;
  border-radius: 50%;
  border: 1px solid #80daeb;
  border: 1px solid var(--contactlessPourBlue);
  background-color: #f5deb3;
}
#logoHandle {
  position: absolute;
  top: 9px;
  right: 3.1px;
  height: 3px;
  width: 2px;
  border: 1.7px solid #2caf1a;
  border-radius: 50px;
}
#logoFlac {
  background-color: #80461b;
  border-color: #2caf1a;
  border-width: 2px;
  height: 21%;
  top: -1px;
}
#logoHeart {
  top: 55%;
  right: 44%;
  bottom: 55%;
  left: 44%;

}
#logoHeart, #logoHeart::before, #logoHeart::after {
  background-color: pink;
}

.hamburgerMenuIcon {
  cursor: pointer;
  background-color: transparent;
  color: var(--primaryText);
  border: none;
  position: relative;
}

div .navbar-collapse {
  align-items: right !important;
}

.innerSidebarContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 47px);
}

.sidebarStyle {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: large;
  text-align: center;
  border: none;
  color: var(--primaryText);
  z-index: 1;
  width: auto;
  overflow-y: auto;
  overflow-x: clip;
}

.mapboxgl-ctrl-geocoder--icon-search {
  display: none !important;
}

.mapboxgl-ctrl-geocoder--input {
  padding: 6px 21px 6px 6px !important;
  height: 36px !important;
}

.mapboxgl-ctrl-geocoder--pin-right > * {
  top: 0;
  right: 0;
}

.mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 0;
  margin-right: 0;
}

.missingVenueForm {
  margin: unset;
  text-align: left;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
}
.missingVenueForm select, .missingVenueForm input {
  min-height: 30px;
  font-size: 20px;
}
.missingVenueForm select {
  width: fit-content;
}
.missingVenueForm button {
  margin-top: 15px;
}

.innerSidebarContainer h1 {
  font-size: 28px;
  margin: 0;
}

h1, h2 {
  line-height: 1.1;
  font-weight: normal;
  margin: 0 auto;
}

.venueCard h2 {
  font-size: 28px;
  margin-top: 0px;
}

.venueHeader {
  padding-bottom: 5px;
}
.websiteLink {
  font-size: 22px;
  margin-left: 50px;
}

.infoBox {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  font-size: 18px;
}

.venueInfo {
  position: relative;
  width: calc(50% - 10px);
  text-align: left;
  border-radius: 6px;
  height: fit-content;
}

.hours {
  border-radius: 0;
  padding-left: 2px;
}

.dayRow {
  display: flex;
  gap: 6px;
}

.day {
  min-width: 28px;
}

.closed {
  color: var(--closed);
}

#expandHoursBtn {
  position: relative;
  top: 3px;
  left: 0;
  height: 15px;
  font-size: 18px;
  background-color: var(--sidebarColor);
  border: none;
  color: var(--primaryText);
  line-height: .6;
  box-shadow: none !important;
  padding-top: 2px;
}

.venueCard {
  padding: 0 2px;
  color: var(--venueCardText);
  text-align: left;
}
.venueCard h3 {
  margin: 4px 0;
  font-size: 22px;
  font-weight: normal;
}

.venueCard p {
  text-align: left;
}

h3 {
  font-size: 14pt;
  font-weight: normal;
  color: var(--primaryText);
}

.welcome {
  width: auto;
  padding: 5px 0;
}

.welcome h1 {
  color: var(--venueCardText);
}

.flexParent {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.flexParent a {
  margin-top: 10px;
}

.button {
  display: flex;
  margin: 10px 0;
  padding: 5px;
  width: fit-content;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  color: var(--primaryText);
  background-color: var(--reviewBtnColor);
  border: none;
  border-radius: 8px;
  box-shadow: 0 5px 0 var(--ballotSection), 0 5px 5px 1px var(--ballotSection);
  transition: all .15s ease-in-out;
}
.button:hover{
  background-color: var(--submit);
}
.button:active {
  margin-top: 15px;
  margin-bottom: 5px;
  box-shadow: 0 0 5px 2px var(--ballotSection);
}

.welcome .message {
  margin: 10px 0;
}

.message {
  margin: 0;
  text-align: left;
}

.centeredMessage {
  margin: 5px auto 0;
}

p {
  font-size: 12pt;
  margin: 2px 0;
}

.row {
  display: flex;
  justify-content: space-evenly;
}

.modalThankYou {
  display: none;
  position: relative;
  text-align: center;
  z-index: 1;
  opacity: 1;
  pointer-events: visible;
  font-size: 24px;
  background-color: var(--modalThankYou);
  color: var(--primaryText);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 7px;
  transition: opacity .4s ease-in-out;
}

.tymBadgeContainer {
  width: 50%;
  padding: 20%;
  min-height: 139px;
  background-image: url(./images/confetti.svg);
  background-size: cover;
  animation: expandBadge 3s 1;
}

.closeBtn {
  border-radius: 5px;
  font-size: 16px;
  background-color: var(--settingOption);
  margin-top: 15px;
}

.disabledBtn-true {
  color: var(--disabledBtnColor) !important;
}

.badgeRow {
  display: flex;
  justify-content: left;
  row-gap: 5px;
  flex-wrap: wrap;
  margin: auto;
}

.badge {
  padding: 1px;
  font-size: 16pt;
  background-color: transparent;
}

.badgeRow .badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32px;
}

.badge + .badge {
  margin-left: 8px;
}

.badgeRow .badge, .userBadges .userBadgeContainer {
  border: 1px solid var(--scrollbarBG);
  box-shadow: 2px 2px 3px deepskyblue;
}

.OkapiIcon {
  filter: invert(0.5) sepia(0.5);
  vertical-align: -webkit-baseline-middle;
}

.mode-dark .GTGCLogo {
  filter: invert(1) hue-rotate(194deg);
}

.svgLogo {
  margin-bottom: -8px;
}

.hasPopup {
  margin-top: 3px;
  position: relative;
}

.popupCarrot {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: var(--navbarColor);
  transform: rotate(45deg);
}

.carrot-pop-up {
  bottom: -4px;
}

.carrot-pop-down {
  top: -4px;
}

.carrot-pop-right {
  left: 2px;
}

.carrot-pop-left {
  right: 2px;
}


.linkPopup {
  position: absolute;
  padding: 5px;
  background-color: var(--navbarColor);
  color: var(--primaryText);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  z-index: 10;
}

.pop-up {
  bottom: calc(100% + 12px);
}

.pop-down {
  top: calc(100% + 12px);
}

.pop-right {
  right: unset !important;
  left: 0 !important;
}

.pop-left {
  left: unset !important;
  right: 0 !important;
}

.pop-up.pop-right {
  border-bottom-left-radius: 0;
}

.pop-down.pop-right {
  border-top-left-radius: 0;
}

.pop-up.pop-left {
  border-bottom-right-radius: 0;
}

.pop-down.pop-left {
  border-top-right-radius: 0;
}

.medium-popup {
  left: calc(50% - 74px);
  width: 150px;
}

.popupLink {
  color: var(--secondaryText);
  font-size: 14px !important;
}

#PFRLogoBtn {
  font-size: 13pt;
  margin-top: 6px;
  padding: 0;
  height: 26px;
  width: 26px;
  background-color: #f5c478;
  color: #af0b16;
  border-radius: 50%;
  font-style: oblique;
  font-weight: 600;
  position: relative;
}

.tooltipBtn {
  height: 24px;
  width: 20px;
  margin-top: -5px;
  border-radius: 50%;
  background-color: var(--buttonColor);
  color: var(--primaryText);
  border: 2px solid var(--settingOption);
  line-height: 15px;
  font-size: 15px;
  padding: 0;
}

.modalThankYou p {
  font-size: 20px;
}

.modalThankYou.modalShowing-true {
  display: flex
}

.sidebarStyle.modalShowing-true {
  display: none;
}

.sidebarStyle.maximize-true {
  height: calc(100vh - 62px);
}

.sidebarStyle.maximize-false {
  height: auto;
}

.flexEl {
  display: flex;
}

.reviewButton, #labelAsCafe {
  width: 70%;
  border-radius: 20px;
  border: 1px solid black;
  background-color: var(--reviewBtnColor);
  color: black;
  font-size: 18px;
  line-height: 18px;
  padding: 3px;
  margin: 5px auto 0;
}

#removePinBtn {
  width: auto;
  border-radius: 20px;
  background-color: pink;
  color: black;
  font-size: 14px;
  line-height: 14px;
  padding: 3px 6px;
  margin-top: 5px;
}

button.linkToLogin {
  border: none;
  background-color: transparent;
  color: var(--secondaryText);
  cursor: pointer;
  padding: 1px;
  font-size: inherit;
}

.deselectVenue {
  position: sticky;
  position: -webkit-sticky;
  left: 100%;
  bottom: 0px;
  height: 32px;
  width: 32px;
  border-radius: 20px 0 0 0;
  border: 1px solid black;
  border-right: none;
  background-color: var(--buttonColor);
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  padding: 0;
  margin-right: -5px;
  cursor: pointer;
}

.addressBtn {
  position: relative;
  background-color: transparent;
  padding: 0;
  border: none;
  line-height: 12px;
  margin-left: 2px;
  margin-top: 3px;
  vertical-align: text-top;
  cursor: pointer;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.textBtn {
  background: transparent;
  border: none;
  color: var(--secondaryText);
}

.boxShadowFocus:focus {
  outline: none;
  box-shadow: 0 0 0 3px #159ce4;
}

#submitComment {
  width: fit-content;
  border-radius: 20px;
  background-color: var(--buttonColor);
  color: var(--primaryText);
  font-size: 18px;
  line-height: 18px;
  padding: 7px 25px;
  margin-top: 5px;
}


@media all and (max-width: 600px) {
  #topLevelContainer {
    position: absolute;
    top: 0;
    font-size: 0pt;
    display: flex;
    flex-direction: column-reverse;
    height: 100%;
    width: 100%;
  }
  
  .outerSidebarStyle {
    position: relative;
    width: auto;
    flex-direction: column-reverse;
    height: fit-content;
    min-height: fit-content;
    top: unset;
  }

  .navigation {
    height: 45px;
    border-bottom: unset;
  }

  .navbarMenu {
    width: calc(100% - 20px);
    top: unset;
    bottom: -15rem;
  }

  .navbarMenu.active {
    top: unset;
    bottom: 45px;
    height: fit-content;
    border-top: 1px solid black;
    border-bottom: unset;
  }

  .innerSidebarContainer {
    height: auto;
    max-height: 70vh;
  }

  .mainContent {
    position: relative;
    width: 100%;
    left: unset;
    right: unset;
    border-left: none;
    height: unset;
    flex-grow: 1;
  }

  .deselectVenue {
    border-bottom: none;
  }
}

@keyframes expandBadge {
  0% {width: 0%};
  100% {width: 100%};
}

