.mugContainer {
    position: relative;
    width: 15px;
    height: 12.375px;
    display: inline-block;
    top: 0;
  }
  
  .mugBody {
    display: flex;
    background-color: #2caf1a;
    height: 97%;
    width: 80%;
    border-radius: 23% 23% 45% 45%;
  }
  
  .flac {
    position: relative;
    border-radius: 50%;
    margin-top: -5%;
    margin-left: 4%;
    height: 12%;
    width: 85%;
    background-color: #2caf1a;
    border-color: var(--sidebarColor);
    border-style: solid;
    border-width: 3px 1px 1px 1px;
  }
  .mugContainer .flac {
    border-width: 1px;
    margin: 0 auto;
    height: 11%;
    top: -1px;
  }
  .contactlessPour .flac {
    border-color: var(--contactlessPourBlue);
  }
  
  .handle {
    position: absolute;
    width: 20%;
    height: 25%;
    border: 4px solid #2caf1a;
    border-left: none;
    border-radius: 0 100% 100% 0;
    right: 2%;
    top: 20%;
  }
  .mugContainer .handle {
    right: -2.6px;
    top: 5px;
    height: 2px;
    width: 1.3px;
    border-width: 2px;
  }
  .contactlessPour .handle {
    box-shadow: 1px 0px 0px 1px var(--contactlessPourBlue);
  }
  
  .heart {
    position: absolute;
    top: 50%;
    right: 34%;
    bottom: 50%;
    left: 34%;
    background-color: var(--sidebarColor);
    border-radius: 0 0 0 35%;
    height: 6px;
    width: 6px;
    transform: rotate(-45deg);
  }
  .heart::after {
    content: "";
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 50%;
    background-color: var(--sidebarColor);
  }
  .heart::before {
    content:"";
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -50%;
    left: 0px;
    background-color: var(--sidebarColor);
  }
  .contactlessPour .heart, .contactlessPour .heart::after, .contactlessPour .heart::before {
    background-color: var(--contactlessPourBlue);
  }
  .mugContainer .heart, .contactlessPour .heart::after, .contactlessPour .heart::before {
    height: 2.5px;
    width: 2.5px;
  }
  .mugContainer .heart {
    top: 64%;
    right: 46%;
    bottom: 64%;
    left: 46%;
  }
  