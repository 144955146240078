.ballotSection h2 {
  margin: -3px -5px 0px -5px;
  font-size: 26px;
  font-weight: 500;
  color: var(--primaryText);
  background-color: var(--aboutContent);
  padding: 6px 10px 2px;
}

h3 {
  margin-top: 2px;
  margin-bottom: 5px;
  text-align: left;
}

.questionCard h3 {
  font-size: 20px;
  margin-left: 5px;
  margin-right: 5px;
  color: black;
}

.ballotSection {
  background-color: var(--ballotSection);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 2px solid black;
  border-bottom: none;
  animation: fadein 0.35s ease-in;
  overflow: hidden;
}

.cafeBallotContainer {
  margin-bottom: 15px;
}

.gridForm {
  display: grid;
  background-color: chocolate;
  border: 2px solid chocolate;
  grid-gap: 2px;
  margin-top: 5px;
  margin: 5px 0 10px 0;
}

.cols-2 {
  grid-template-columns: auto 1fr 1fr;
}

.cols-3 {
  grid-template-columns: auto 1fr 1fr 1fr;
}

.colLabel {
  background-color: lightblue;
  text-align: center;
  padding: 2px;
  font-size: 16px;
  font-weight: normal;
  color: black;
}

.rowLabel {
  text-align: end;
  padding: 2px;
  font-size: 16px;
  font-weight: normal;
  color: black;
}

.even-row {
  background-color: var(--settingOption);
}

.odd-row {
  background-color: tan;
}

.cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

.questionOptions {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: hidden;
  border-radius: 5px;
  padding: 2px;
  gap: 2px;
  border: 1px solid var(--checkedColor);
  background-color: var(--checkedColor);
}

.questionOptions input {
  display: none;
}

.questionOptions label, .settingOption {
  flex: 1;
  background-color: var(--settingOption);
  padding: 6px 3px;
  margin: 0;
  white-space: nowrap;
  color: black;
  font-size: 17px;
  font-weight: normal;
  text-align: center;
  border: none;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
}

.questionOptions label:hover, .settingOption:hover{
	cursor: pointer;
}

.questionOptions input:checked + label {
  background-color: var(--checkedColor);
  color: white;
	box-shadow: none;
}

.checked-true {
  background-color: var(--checkedColor);
  color: white;
	box-shadow: none;
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 3px #159ce4;
}

.ballot-radio {
  display: flex;
  flex-wrap: wrap;
}

.ballot-ctrl-container {
  display: flex;
  width: 100%;
  gap: 0;
}

.backNextContainer {
  width: calc(100% + 4px);
  margin: 20px 0 0 -2px;
  display: flex;
  gap: 0;
}

.ballot-ctrl {
  flex-grow: 1;
}

.cafeBallotBtn {
  margin: 0;
  padding: 7px 1px;
  font-size: 19px;
  cursor: pointer;
  flex-grow: 1;
  color: var(--primaryText);
  border: 1px solid var(--primaryText);
}

.nextQBtn {
  background-color: var(--nextBtn);
  /* border-right: 2px solid black; */
  border-bottom: none;
  width: 50%;
}

.backBtn {
  background-color: var(--buttonColor);
  /* border-left: 2px solid black; */
  border-bottom: none;
  width: 50%;
}

#submitReview {
  border-bottom-left-radius: 16px;
  background: var(--submit);
  width: 50%;
}

#resetBallot {
  border-bottom-right-radius: 16px;
  background: var(--failureColor);
  width: 50%;
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 0.5s linear;
}

@-webkit-keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fadeIn {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

